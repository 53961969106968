.criticalList {
  border-left: 4px solid red;
  list-style-type: none;
  padding-left: 8px;
  margin: 4px 0px 0px 2px;
}
.criticalList li {
  margin-top: 0.5em;
}
.warningList li {
  margin-top: 0.5em;
}

.warningContainer {
  margin-top: 1em;
  margin-bottom: 2em;
}

.criticalContainer {
  margin-top: 0.5em;
}

.warningList {
  border-left: 4px solid #f8e636;
  list-style-type: none;
  padding-left: 8px;
  margin: 4px 0px 0px 2px;
}

.recommendationContainer {
  margin-top: 1em;
}
