.ProjectScreen-FullscreenContainer {
  padding-bottom: var(--padding-fix);
}

.ProjectScreen-Table {
  flex: 1;
}

.ProjectScreen-FullscreenContainer .PaddingContainer {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 24px;
}
