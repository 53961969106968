.SelectContainer {
  width: 100%;
  align-self: center;
  justify-self: center;
}

.SelectContainer .MuiInputLabel-outlined.Mui-focused {
  color: #3874cb !important;
  border-style: none !important;
  border-bottom: none !important;
  background: #15202b !important;
  padding-left: 10px;
  padding-right: 10px;
}
.SelectContainer label.MuiFormLabel-filled {
  color: #bcbcbc !important;
  border-style: none;
  border-radius: 32px;
}

.SelectContainer .Mui-focused fieldset {
  border: 1px !important;
  border-color: white !important;
  border-style: solid !important;
  /* border-top: none !important; */
}

.SelectContainer .MuiSelect-selectMenu {
  border-radius: 32px !important;
}

.SelectContainer .MuiSelect-icon {
  color: white;
}
