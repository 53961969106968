@import url("https://fonts.googleapis.com/css2?family=Ramaraja&family=Roboto:wght@100&display=swap");

.TopContainer-Document {
  height: "100%";
  width: "100%";
  display: flex;
  justify-content: center;
  background-color: #15202b;
}

.SubContainer-Document {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.NavContainer-Document {
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: "100%";
}

.HeaderContainer-Document {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  width: 100%;
}

.BurgerContainer-Document {
  flex: 0.2;
}

.TextContainer-Document {
  flex: 0.8;
}

.ButtonContainer-Document {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-height: 50px;
  bottom: 10px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.PreviewContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  background-color: white;
  min-height: 100%;
}

.PreviewSub {
  margin-left: 24px;
  margin-right: 24px;
}

.PreviewLogo {
  margin-top: 24px;
  margin-bottom: 12px;
  font-family: Ramaraja;
  font-size: xx-large;
  background-color: white;
}

.InfoContainer {
  margin-top: 24px;
}

.TextAreaContainer-Document {
  margin-top: 12px;
  width: 100%;
}

.InfoContainer#summary {
  margin-top: 0px;
}
.InfoContainer#notes {
  margin-bottom: 12px;
}

.AccordionGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.AnchorTitleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.DisclaimerDiv p {
  font-size: x-small;
  color: black;
  font-weight: 500;
}

.AnchorTable tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}
.AnchorTable {
  border-collapse: collapse;
  width: 100%;
}

.AnchorTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #15202b;
  color: white;
  border: 1px solid #ddd;
  padding: 8px;
}

.AnchorTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.AnchorTable tbody tr:hover {
  background-color: #ddd;
}

#reference {
  font-size: smaller;
}
