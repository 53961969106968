.FormScreen-StepperContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
}

.FormScreen-ContentContainer {
  flex: 1;
  overflow-x: hidden;
}

.FormScreen-ButtonContainer {
  align-items: center;
  padding-top: 24px;
}

.PaddingContainer {
  padding-left: 24px;
  padding-right: 24px;
}

.FormScreen-Top-NavbarShadow {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  z-index: 100;
}

.FormScreen-Bottom-NavbarShadow {
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  z-index: 100;
}
