.multiImgContainer {
  max-height: auto;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 3em;
}

.multiButtonGroup {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}

.listImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: bottom;
  width: 100%;
  margin-bottom: 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loadingSpinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
