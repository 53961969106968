tbody tr:hover {
  background-color: #263341;
}

.MuiTableCell-head.tableHeaderCell {
  user-select: none;
}

.MuiTableCell-body.tableBodyCell {
  -webkit-user-select: none;
  word-break: break-word;
  user-select: none;
  border-style: none;
}
