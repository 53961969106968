@import url(https://fonts.googleapis.com/css2?family=Ramaraja&family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ramaraja&family=Roboto:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #15202b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


:root {
  --padding-fix: 24px;
}

.FullscreenContainer {
  background-color: #15202b;
  padding-top: 24px;
  padding-top: var(--padding-fix);
  padding-bottom: var(--padding-bottom-fix);
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(
    1vh * 100 - 24px -
      0px);
  height: calc(
    var(--vh, 1vh) * 100 - var(--padding-fix, 0px) -
      var(--padding-bottom-fix, 0px)
  );
}

.TopContainer-NavBarContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.TopContainer-NavBarContainer #Text {
  flex: 1 1;
  margin-left: 18px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.TopContainer-NavBarContainer #EndContent {
  align-self: center;
}




.InputContainer .MuiInputLabel-outlined.Mui-focused {
  color: #3874cb !important;
  border-style: none;
}
.InputContainer .MuiFormLabel-filled {
  color: #bcbcbc !important;
  border-style: none;
}

.InputContainer .Mui-focused fieldset {
  border: 1px !important;
  border-color: white !important;
  border-style: solid !important;
}

.Module-Header {
  padding-top: 6px;
  padding-bottom: 6px;
}



.SelectContainer {
  width: 100%;
  align-self: center;
  justify-self: center;
}

.SelectContainer .MuiInputLabel-outlined.Mui-focused {
  color: #3874cb !important;
  border-style: none !important;
  border-bottom: none !important;
  background: #15202b !important;
  padding-left: 10px;
  padding-right: 10px;
}
.SelectContainer label.MuiFormLabel-filled {
  color: #bcbcbc !important;
  border-style: none;
  border-radius: 32px;
}

.SelectContainer .Mui-focused fieldset {
  border: 1px !important;
  border-color: white !important;
  border-style: solid !important;
  /* border-top: none !important; */
}

.SelectContainer .MuiSelect-selectMenu {
  border-radius: 32px !important;
}

.SelectContainer .MuiSelect-icon {
  color: white;
}

.Divider {
  border-style: solid none none none;
  border-width: 0.1px;
  border-color: white;
  margin-top: 12px;
  padding-bottom: 12px;
}


.TopContainer-Document {
  height: "100%";
  width: "100%";
  display: flex;
  justify-content: center;
  background-color: #15202b;
}

.SubContainer-Document {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.NavContainer-Document {
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: "100%";
}

.HeaderContainer-Document {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  width: 100%;
}

.BurgerContainer-Document {
  flex: 0.2 1;
}

.TextContainer-Document {
  flex: 0.8 1;
}

.ButtonContainer-Document {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-height: 50px;
  bottom: 10px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.PreviewContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  background-color: white;
  min-height: 100%;
}

.PreviewSub {
  margin-left: 24px;
  margin-right: 24px;
}

.PreviewLogo {
  margin-top: 24px;
  margin-bottom: 12px;
  font-family: Ramaraja;
  font-size: xx-large;
  background-color: white;
}

.InfoContainer {
  margin-top: 24px;
}

.TextAreaContainer-Document {
  margin-top: 12px;
  width: 100%;
}

.InfoContainer#summary {
  margin-top: 0px;
}
.InfoContainer#notes {
  margin-bottom: 12px;
}

.AccordionGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.AnchorTitleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.DisclaimerDiv p {
  font-size: x-small;
  color: black;
  font-weight: 500;
}

.AnchorTable tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}
.AnchorTable {
  border-collapse: collapse;
  width: 100%;
}

.AnchorTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #15202b;
  color: white;
  border: 1px solid #ddd;
  padding: 8px;
}

.AnchorTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.AnchorTable tbody tr:hover {
  background-color: #ddd;
}

#reference {
  font-size: smaller;
}




.imgPreview {
  text-align: center;
  margin: 0.5em 1.5em;
  max-height: auto;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  max-width: 60%;
  display: flex;
  flex-direction: column;
}
.imgPreview img {
  width: 100%;
  height: 100%;
}

.imgContainer {
  max-height: auto;
  max-width: auto;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imgButtonGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1em;
}

.loadingSpinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.multiImgContainer {
  max-height: auto;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 3em;
}

.multiButtonGroup {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}

.listImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: bottom;
  width: 100%;
  margin-bottom: 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loadingSpinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.criticalList {
  border-left: 4px solid red;
  list-style-type: none;
  padding-left: 8px;
  margin: 4px 0px 0px 2px;
}
.criticalList li {
  margin-top: 0.5em;
}
.warningList li {
  margin-top: 0.5em;
}

.warningContainer {
  margin-top: 1em;
  margin-bottom: 2em;
}

.criticalContainer {
  margin-top: 0.5em;
}

.warningList {
  border-left: 4px solid #f8e636;
  list-style-type: none;
  padding-left: 8px;
  margin: 4px 0px 0px 2px;
}

.recommendationContainer {
  margin-top: 1em;
}


.FormScreen-StepperContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
}

.FormScreen-ContentContainer {
  flex: 1 1;
  overflow-x: hidden;
}

.FormScreen-ButtonContainer {
  align-items: center;
  padding-top: 24px;
}

.PaddingContainer {
  padding-left: 24px;
  padding-right: 24px;
}

.FormScreen-Top-NavbarShadow {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  z-index: 100;
}

.FormScreen-Bottom-NavbarShadow {
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  z-index: 100;
}

.LoginScreen-FullscreenContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SubContainer-LoginScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
  max-width: 600px;
}

.LogoContainer-LoginScreen {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.InputContainer-LoginScreen {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  width: 100%;
}

.ButtonContainer-LoginScreen {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ProfileScreen-Content {
  padding-top: 16px;
}

tbody tr:hover {
  background-color: #263341;
}

.MuiTableCell-head.tableHeaderCell {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.MuiTableCell-body.tableBodyCell {
  -webkit-user-select: none;
  word-break: break-word;
  -ms-user-select: none;
      user-select: none;
  border-style: none;
}

.react-contextmenu {
  background-color: #15202b;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 80px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 9px;
}

.react-contextmenu-item {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: "Roboto";
  -ms-user-select: "none";
      user-select: "none";
  -webkit-user-select: none;
  color: #ffffff;
}

.react-contextmenu-item:hover {
  background-color: #263341;
}

.react-contextmenu-item.react-contextmenu-item--selected {
  outline-width: 0;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}

.ProjectScreen-FullscreenContainer {
  padding-bottom: var(--padding-fix);
}

.ProjectScreen-Table {
  flex: 1 1;
}

.ProjectScreen-FullscreenContainer .PaddingContainer {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 24px;
}

body {
  background-color: #15202b;
}

