.imgPreview {
  text-align: center;
  margin: 0.5em 1.5em;
  max-height: auto;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  max-width: 60%;
  display: flex;
  flex-direction: column;
}
.imgPreview img {
  width: 100%;
  height: 100%;
}

.imgContainer {
  max-height: auto;
  max-width: auto;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imgButtonGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1em;
}

.loadingSpinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
