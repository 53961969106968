.InputContainer .MuiInputLabel-outlined.Mui-focused {
  color: #3874cb !important;
  border-style: none;
}
.InputContainer .MuiFormLabel-filled {
  color: #bcbcbc !important;
  border-style: none;
}

.InputContainer .Mui-focused fieldset {
  border: 1px !important;
  border-color: white !important;
  border-style: solid !important;
}
