.react-contextmenu {
  background-color: #15202b;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 80px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 9px;
}

.react-contextmenu-item {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: "Roboto";
  user-select: "none";
  -webkit-user-select: none;
  color: #ffffff;
}

.react-contextmenu-item:hover {
  background-color: #263341;
}

.react-contextmenu-item.react-contextmenu-item--selected {
  outline-width: 0;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
